import React, { useContext, useEffect, useRef, useState } from "react";
import Icon from "components/Icon";
import AddPromptModal from "components/AddPromptModal";
import EditPromptModal from "components/EditPromptModal";
import SharePromptModal from "components/SharePromptModal";

import { PromptsContext } from "context/promptsContext";
import { useUsersContext } from "context/usersContext";
import DeleteChatModal from "components/DeleteChatModal";
import axios from "axios";
import env from "config";
import { useHistory } from "react-router-dom";

const Sidebar2 = ({ text, LoadTaskonchat, assistantData, toggleSidebar, asistantSelected }) => {
  const { promptsData, getPrompts, favouritesData, getFavourites, handleRefreshData } = useContext(PromptsContext);
  const { SSO, showToast, getLocationUsers, locationUsers, setOnFromPrompt, ChatSubmit, setUserAsUnread, addNewMessage, refresh } = useUsersContext();
  const history = useHistory();
  const [isOpendrop, setIsOpendrop] = useState(false);
  const [isfilterapplied, setIsfilterapplied] = useState(false);
  const [threeDotOptions, setThreeDotOptions] = useState({});
  const [moreOption, setMoreOption] = useState(false)
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const [askToDeleteModal, setAskToDeleteModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(false);
  const [isOpenEditPromptModal, setIsOpenEditPromptModal] = useState(false);
  const [askToShareModal, setAskToShareModal] = useState(false);
  const [isEditPrompt, setIsEditPrompt] = useState();
  const [subcategories, setsubcategories] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const BASE_URL = `${env.API_URL}/v1`;
  const favPromptIds = favouritesData?.map(fav => fav.prompt_id);
  const favPrompts = promptsData?.prompt?.filter(prompt => favPromptIds?.includes(prompt._id));


  const closeNav = () => {
    document.getElementById("tasksidebar").style.width = "0";
  };

  const toggleDropdownd = () => setIsOpendrop(!isOpendrop);


  const threeDotRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (threeDotRef.current && !threeDotRef.current.contains(event.target)) {
        setThreeDotOptions({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [threeDotRef]);

  const toggleThreeDotOption = (index) => {
    setThreeDotOptions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const addPromptHandle = () => {
    setIsAddPromptModal(true);
  }
  const justCloseIt = () => {
    setIsAddPromptModal(false);
    setAskToDeleteModal(false);
    setSelectedContact(false);
    setIsOpenEditPromptModal(false);
    setAskToShareModal(false);
    setMoreOption(false)
    setThreeDotOptions(false);
    getPrompts();
  }

  const orderedCategories = [
    "Get Started",
    "Grow your business",
    "Marketing",
    "Sites",
    "Automation",
    "Insights",
    "Ask an expert"
  ];

  const sortedCategories = promptsData?.prompt_category.filter((item) => item.type === 'super-admin').sort((a, b) => {
    const indexA = orderedCategories.indexOf(a.name);
    const indexB = orderedCategories.indexOf(b.name);

    // Categories in orderedCategories should come first, others at the end
    if (indexA === -1) return 1; // If a is not in the custom order, move it to the end
    if (indexB === -1) return -1; // If b is not in the custom order, move a before b
    return indexA - indexB; // Sort based on the custom order
  });

  const limitWords = (text, wordLimit) => {
    if (!text) return ""; // Return empty if no text
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };



  const askToDelete = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToDeleteModal(true);
  }
  const handleDeleteClick = async () => {
    const response = await axios.get(`${BASE_URL}/chat/promptDelete?_id=${selectedContact._id}`);
    if (response) {
      // setUsers((users) => users.filter((user) => user.id !== selectedContact?.id));
      const redirectUrl = `/`;
      history.push(redirectUrl);
      justCloseIt();
      getPrompts();
    } else {
      showToast('Something went wrong!')
    }
  };
  const handleEditPrompt = (e, prompt) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditPrompt(prompt)
    setIsOpenEditPromptModal(true)
  }
  const askToShare = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToShareModal(true);
  }
  const getsubcateogories = (e, id, currentname) => {
    e.stopPropagation();
    e.preventDefault();
    // const matches = [];

    // for (let category of promptsData?.prompt_category) {
    //   for (let subCategory of promptsData?.prompt_sub_category) {
    //     if (subCategory.category_id === id && category.name === currentname) {
    //       matches.push({
    //         category: category.name,
    //         subCategory: subCategory.name,
    //         category_id: category._id,
    //         subCategory_id: subCategory._id,
    //       });
    //     }
    //   }
    // }
    // console.log(matches,"zainn")
    // setsubcategories(matches)

  }
  const moveToCategoryHandler = async (e, item, categoryData) => {
    e.preventDefault();
    const payload = {
      promptId: item._id,
      category: categoryData?._id,
      // sub_category: categoryData?.subCategory_id
    };
    return await axios
      .put(BASE_URL + "/chat/shareprompt", payload)
      .then(async function (response) {
        justCloseIt();
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const SortByCategory = (e, category) => {
    setSelectedCategoryId(category);
    setIsOpendrop(false);
    setIsfilterapplied(true);
  };
  const removeFilter = () => {
    setSelectedCategoryId();
    setIsfilterapplied(false);
    setIsOpendrop(false);

  }

  const handleFavourite = async (e, prompt) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await axios.get(`${BASE_URL}/chat/add/favourite?prompt_id=${prompt?._id}&user_id=${SSO?.userUUID}&location_id=${SSO?.id}`);
    if (response) {
      getFavourites();
      handleRefreshData(); justCloseIt();
    }
  }
  const removeFromFav = async (e, prompt) => {
    e.stopPropagation();
    e.preventDefault();
    const favoriteId = favouritesData?.find(favorite => favorite.prompt_id === prompt?._id)?._id;
    const response = await axios.get(`${BASE_URL}/chat/remove/favourite?chatId=${favoriteId}&user_id=${SSO?.userUUID}&location_id=${SSO?.id}`);
    if (response) {
      getFavourites();
      handleRefreshData(); justCloseIt();
    }
  }

  const handleCombineFunction = (title, value) => {
    LoadTaskonchat(title, value)
    closeNav();
  }

  return (
    <div >
      <DeleteChatModal
        modelType="Task"
        isModalOpen={askToDeleteModal}
        contact={selectedContact}
        yesDelete={handleDeleteClick}
        justCloseIt={justCloseIt}
        type="prompt"
      />
      {
        isEditPrompt && (
          <EditPromptModal
            isModalOpen={isOpenEditPromptModal}
            categories={promptsData?.prompt_category?.filter(obj => obj.type !== 'super-admin')}
            subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type !== 'super-admin')}
            justCloseIt={justCloseIt}
            getUpdatePromptsData={getPrompts}
            editadata={isEditPrompt}
          />
        )
      }
      <SharePromptModal
        isModalOpen={askToShareModal}
        contact={selectedContact}
        justCloseIt={justCloseIt}
        getLocationUsers={getLocationUsers}
        locationUsers={locationUsers}
      />
      <div id="tasksidebar" className="v3_sec_sidebar" >
        <div className="stickyTop">
          <span className="crossicon" style={{ margin: "0px 6px 0px 0px" }} onClick={closeNav}>
            ×
          </span>

          <div className="v3_searchMain">
            <div style={{ margin: "0px 12px 0px 0px", width: "60%" }}>
              <div
                className="v3_dropdowninchat_filter_task position-relative"
                onClick={toggleDropdownd}
              >
                <span style={{
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  paddingLeft: "20px",
                  color: "#757575"

                }} id="appendsavelocation">
                  {selectedCategoryId ? selectedCategoryId.name : "Sort By Category"}
                </span>
                <span
                  className="v3_togglebtn"
                  style={{
                    transform: isOpendrop ? 'rotate(135deg)' : 'rotate(-45deg)',
                  }}
                ></span>
                {isfilterapplied ?

                  <span className="filtericoncroxx_v3" onClick={removeFilter}>X</span> :
                  <span className="filtericonsetting_v3"><Icon id="v3_filter" /></span>}

              </div>
              {isOpendrop && (
                <div className="v3_menuItems_taskbar">
                  <div style={{
                    padding: '10px',
                  }}>
                    <div className="v3_dropdown_optionvalue">
                      <div style={{ width: "100%" }}>
                        {sortedCategories.map((category, index) => (
                          <p className="category_name" onClick={(e) => SortByCategory(e, category)} key={index}>
                            {category.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div style={{ margin: "7px 0px 0px 10px" }}>
              <button className="v3_taskbutton-round" onClick={addPromptHandle}>Add Task</button>
            </div>
          </div>

          <h2 className="result_3">
            {promptsData?.prompt !== null && promptsData?.prompt?.filter(item => !selectedCategoryId?._id || item.category === selectedCategoryId?._id).length}
            &nbsp; Result
          </h2>

        </div>

        <div ref={threeDotRef}>
          {promptsData?.prompt !== null && promptsData?.prompt?.filter(item => !selectedCategoryId?._id || item.category === selectedCategoryId?._id).filter(item => favPrompts.some(favItem => favItem._id === item._id))
            .map((item, index) => (
              <div
                className="box_2"
                key={index}
              >
                <span className="three_dots" style={{ padding: "5px" }} onClick={(e) => { e.stopPropagation(); removeFromFav(e, item); }}>
                  <Icon id="pintask" />
                </span>

                <div onClick={() => handleCombineFunction(item.title, item.prompt_value)}>
                  <div style={{ marginBottom: "7px", width: "95%" }}>
                    <b className="box_2_b">{item.title}</b>
                  </div>

                  <p className="box_2_p">{limitWords(item.prompt_value, 18)}</p>
                </div>
              </div>

            ))}
          {promptsData?.prompt !== null && promptsData?.prompt?.filter(item => !selectedCategoryId?._id || item.category === selectedCategoryId?._id)
            .filter(item => !favPrompts.some(favItem => favItem._id === item._id))
            .map((item, index) => (
              <div className="box_2" key={index}  >
                <span className="three_dots" onClick={() => toggleThreeDotOption(index)}>
                  <Icon id="threeDots" />
                </span>

                {
                  threeDotOptions[index] && (
                    <div className="v3_three_dots_options">
                      <div className="v3_three_dots_options_box" onClick={(e) => handleFavourite(e, item)}>
                        <div className="v3_three_dots_options_icon">
                          <Icon id="pintask" />
                        </div>
                        <p className="v3_three_dots_options_text">Pin</p>
                      </div>

                      {item?.type !== 'super-admin' && item?.type !== 'agency' && item?.type !== 'agency-admin' && (
                        <>
                          <div className="v3_three_dots_options_box" onClick={(e) => handleEditPrompt(e, item)}>
                            <div className="v3_three_dots_options_icon">
                              <Icon id="pencilblack" />
                            </div>
                            <p className="v3_three_dots_options_text">Edit</p>
                          </div>

                          <div className="v3_three_dots_options_box" onClick={(e) => askToShare(e, item)}>
                            <div className="v3_three_dots_options_icon">
                              <Icon id="share" />
                            </div>
                            <p className="v3_three_dots_options_text">Share</p>
                          </div>

                          <div className="v3_three_dots_options_box">
                            <div className="v3_hover-text" style={{ display: "flex", justifyContent: "space-between" }}>
                              <div className="v3_three_dots_options_icon">
                                <Icon id="move" />
                              </div>
                              <p className="v3_three_dots_options_text" onClick={(e) => getsubcateogories(e, item._id, item.name, setMoreOption(!moreOption))}>Move</p>
                              {moreOption && (
                                <div className="v3_more_dropdown" style={{ maxHeight: "200px", overflow: "auto" }}>
                                  {promptsData?.prompt_category.map((category, index) => (
                                    <>
                                      {category.type === "agency-admin" && (
                                        <span onClick={(e) => moveToCategoryHandler(e, item, category)}>
                                          {category.name}
                                        </span>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="v3_three_dots_options_box" onClick={(e) => askToDelete(e, item)}>
                            <div className="v3_three_dots_options_icon">
                              <Icon id="delete" />
                            </div>
                            <p className="v3_three_dots_options_text">Delete</p>
                          </div>
                        </>
                      )}

                    </div>
                  )
                }

                <div onClick={() => handleCombineFunction(item.title, item.prompt_value)}>
                  <div style={{ marginBottom: "7px", width: "95%" }}>
                    <b className="box_2_b">{item.title}</b>
                  </div>
                  <p className="box_2_p">{limitWords(item.prompt_value, 18)}</p>
                </div>

              </div>
            ))}



        </div>

      </div>

      <div className="v3_sec_sidebar__openbtn" onClick={() => toggleSidebar('task')}>
        {text}
      </div>
      <AddPromptModal
        assistantData={assistantData}
        isModalOpen={isOpenAddPromptModal}
        categories={promptsData?.prompt_category?.filter(obj => obj.type !== 'super-admin')}
        subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type !== 'super-admin')}
        justCloseIt={justCloseIt}
        getUpdatePromptsData={getPrompts}
        asistantSelected={asistantSelected}
      />
    </div >
  );
};

export default Sidebar2;
