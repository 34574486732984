import env from "config";
import React, { useEffect } from "react";
const GDriveURL = `${env.GDriveURL}`;

const GoogleDrivePickers = ({ files_send_to_parent, disabled }) => {
  const openPicker = () => {
    const popup = window.open(
      GDriveURL,
      "GoogleDrivePicker",
      "width=900,height=500"
    );

    if (!popup) {
      alert("Popup blocked! Please allow popups for this site.");
      return;
    }

    const interval = setInterval(() => {
      if (popup.closed) {
        clearInterval(interval);
        console.log("Popup closed by user.");
      } else {
        try {
          popup.postMessage({ action: "openPicker" }, "*");
          clearInterval(interval); // Stop the interval after message is sent
        } catch (err) {
          console.error("Error posting message:", err);
        }
      }
    }, 500);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      console.log("Files received:", event.data.action, event.data.files);
      if (event.data.action === "filesSelected") {
        files_send_to_parent(event.data.files);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div>
      <button  onClick={openPicker} style={{ background: "none", border: "none" }}>
        Connect Google Drive
      </button>
    </div>
  );
};

export default GoogleDrivePickers;
