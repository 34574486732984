import React, { useEffect } from 'react';
import axios from 'axios';

const DropboxChooser = ({ files_send_to_parent, setSpinnerLoading, disabled }) => {
  const CLIENT_ID = process.env.DROPBOX_CLIENT_ID; // Make sure to define this in your .env file
  const appKey = CLIENT_ID || 'ic6o1zff7ar94a1';

  // Load Dropbox script dynamically
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.type = 'text/javascript';
    script.setAttribute('data-app-key', appKey);
    document.body.appendChild(script);
  }, [appKey]);

  // Open Dropbox Chooser and handle file selection
  const handleDropboxClick = () => {
    if (!window.Dropbox) {
      console.error('Dropbox SDK is not loaded.');
      return;
    }

    window.Dropbox.choose({
      success: async (files) => {
        const processedFiles = await Promise.all(files.map(downloadFile));
        files_send_to_parent(processedFiles, 'dropbox');
        // setSelectedFiles((previous) => [...previous, ...files]);
        console.log(files, "dropbox file")
        setSpinnerLoading(true)
      },
      cancel: () => {
        console.log('Dropbox Picker cancelled');
      },
      linkType: 'direct', // or 'preview' as needed
      multiselect: true, // Adjust as needed
      extensions: ['.pdf', '.doc', '.docx', '.txt', '.csv', '.json', '.html', '.xlsx'],
    });
  };

  // Download the file and create a File object
  const downloadFile = async (file) => {
    try {
      const response = await axios.get(file.link, { responseType: 'blob' });
      const blob = response.data;
      const fileType = file.link.split('.').pop();
      return new File([blob], file.name, { type: fileType });
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div>
      <button onClick={handleDropboxClick} style={{ background: "none", border: "none" }}>Connect to Dropbox</button>
    </div>
  );
};

export default DropboxChooser;
